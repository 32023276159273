html {
  font-size: 62.5%;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: $m-size;
  background: $main-color;
}

h1 {
  font-size: 2.2rem;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}
