.option {
  display: flex;
  justify-content: space-between;
  padding: $l-size $m-size;

  border-bottom: 1px solid $third-color2;
}

.option__text {
  color: white;
  font-weight: 500;
  font-size: 2rem;
  margin: 0;
  word-break: break-all;
}
