.add-option {
  display: flex;
  padding: $m-size;
  flex-direction: column;
}

.add-option-error {
  color: white;
  font-style: italic;
  margin: $m-size 0 0 0;
  padding: 0 $m-size;
}

.add-option__input {
  flex-grow: 1;
  background: $main-color;
  border: none;
  border-bottom: 0.3rem solid $third-color2;
  margin: 0 0 $s-size 0;
  color: white;
  padding: $s-size;
}

@media (min-width: $desktop_breakpoint) {
  .add-option {
    flex-direction: row;
  }
  .add-option__input {
    margin: 0 $s-size 0 0;
  }
}
