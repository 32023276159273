.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 500ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  background: $main-color;
  color: $seconday-color;
  max-width: 50rem;
  outline: none;
  padding: $l-size;
  text-align: center;
}

.modal__title {
  margin: 0 0 $m-size 0;
}

.modal__text {
  font-size: 2rem;
  font-weight: 300;
  font-style: italic;
  margin: 0 0 $l-size 0;
  word-break: break-all;
  color: white;
}
