//color

$main-color: #4a4d57;
$seconday-color: #27ad63;
$third-color: #464b5e;
$third-color2: #3c4251;

//size
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-mobile-size: 2.4rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop_breakpoint: 45rem;
