.widget {
  background: $third-color;
  margin-bottom: $xl-size;
}

.widget-header {
  background: $third-color2;
  color: white;
  padding: $m-size;
  display: flex;
  justify-content: space-between;
}

.widget-header__title {
  margin: 0;
  color: white;
}

.widget-message {
  color: white;
  margin: 0;
  padding: $l-size;
  text-align: center;
  border-bottom: 1px solid $third-color2;
}
